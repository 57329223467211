import {
  SEARCH_MRD_RES,
  MRD_API_RES,
  GET_REQ_LIST,
  GET_REQ_COUNT,
  GET_PEND_COUNT,
  VALIDATION_MRD_RES,
} from '../../shared/constants/ActionTypes';

const initialState = {
  searchMrdRes: [],
  MrdReqValidation: '',
  mrdApiRes: '',
  getReqList: [],
  getReqCount: 0,
  getPendCount: 0,
};

const mrdUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_MRD_RES:
      return {
        ...state,
        searchMrdRes: action.payload,
      };
    case VALIDATION_MRD_RES:
      return {
        ...state,
        MrdReqValidation: action.payload,
      };
    case GET_REQ_LIST:
      return {
        ...state,
        getReqList: action.payload,
      };
    case GET_REQ_COUNT:
      return {
        ...state,
        getReqCount: action.payload,
      };
    case MRD_API_RES:
      return {
        ...state,
        mrdApiRes: action.payload,
      };
    case GET_PEND_COUNT:
      return {
        ...state,
        getPendCount: action.payload,
      };
    default:
      return state;
  }
};

export default mrdUserReducer;
